import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const AuthRoutePmr = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const userUuid = sessionStorage.getItem('userUUID');
    if (userUuid) {
      // Effettua la chiamata fetch per verificare l'UUID
      fetch('/fmMontano/controlla-uuidPmr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userUuid),
      })
      .then(response => {
        // Se lo status della risposta è 200, l'utente è autenticato
        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          // Altrimenti, considera l'utente non autenticato
          setIsAuthenticated(false);
        }
      })
      .catch(error => {
        console.error('Errore durante la verifica dell\'UUID:', error);
        setIsAuthenticated(false);
      });
    } else {
      // Se non c'è un UUID, considera direttamente l'utente non autenticato
      setIsAuthenticated(false);
    }
  }, []);

  if (isAuthenticated === null) {
    return <div>Verifica in corso...</div>;
  } else if (isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default AuthRoutePmr;
