import React from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { FaHome, FaEdit, FaSignOutAlt, FaListAlt, FaTrophy , FaMapMarkedAlt, FaUserTie  } from 'react-icons/fa';
import { resetUser } from './store';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function RegolamentoRadio() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    dispatch(resetUser());
    navigate('/login');
  };

  return (
    <>
      <Navbar fixed="top" expand="false" style={{
        borderBottom: '1px solid #dee2e6',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(5px)',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}>
        <Container>
        <Navbar.Brand href="#" style={{ color: 'black', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
            <img src="/c1248ddd-5064-4e70-8c13-4d8b7af0a65e.png" alt="FM Montano Logo" style={{ height: '40px', marginRight: '10px' }} />
            RadioMobile
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'black' }} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => navigate('/gestione-attivita_radio')} style={{ color: 'black' }}>
                <FaEdit /> Gestione Attività
              </Nav.Link>
              <Nav.Link onClick={() => navigate('/elenco-attivita_radio')} style={{ color: 'black' }}>
                <FaListAlt /> Elenco Attività
              </Nav.Link>

              <Nav.Link onClick={() => navigate('/mappa_radio')} style={{ color: 'black' }}>
              <FaMapMarkedAlt /> Mappa Referenze
              </Nav.Link>

              {/* Dropdown per le classifiche */}
              <NavDropdown title={<span><FaTrophy /> Classifiche</span>} id="basic-nav-dropdown" style={{ color: 'black' }}>
                <NavDropdown.Item onClick={() => navigate('/classifica-attivita_radio')}>Classifica Attivatori 2024</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/classifica-cacciatori-2024_radio')}>Classifica Cacciatori 2024</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link onClick={() => navigate('/direttivo_radio')} style={{ color: 'black' }}>
                <FaUserTie  /> Direttivo
              </Nav.Link>
              <Nav.Link onClick={() => navigate('/home_radio')} style={{ color: 'black' }}>
                <FaHome /> Home
              </Nav.Link>
              <Nav.Link onClick={handleLogout} style={{ color: 'black', marginLeft: '20px' }}>
                <FaSignOutAlt /> Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "95vh",
          backgroundImage: 'url("/login_radio.webp")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          paddingTop: '56px'
        }}
      >
        <Container style={{
          padding: 0,
          maxWidth: "100%",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}>
          <div className="text-center" style={{
            backgroundColor: "rgba(192, 192, 192, 0.7)",
            padding: "20px",
            borderRadius: "15px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            maxWidth: "600px"
          }}>
            <img src="/c1248ddd-5064-4e70-8c13-4d8b7af0a65e.png" alt="FM Montano Logo" style={{ maxWidth: '150px', marginBottom: '1rem' }} />
            <h2 style={{ color: 'red', fontSize: '20px' }}>Regolamento</h2>
            <div style={{ textAlign: 'left', lineHeight: '1.6', fontSize: '16px', color: 'black' }}>
              E’ ritenuta valida un’attivazione che rispetta i seguenti requisiti:
              <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                <li>1) Potenza massima di emissione: non sono previsti limiti alla potenza in uscita, sempre nel rispetto delle norme vigenti.</li>
                <li>2) Attività da una delle referenze presenti su lista Radiomobile. I QSO dovranno pervenire sull'apposita piattaforma di caricamento nonché sito web www.fmmontano.com.</li>
                <li>3) Almeno n°1 QSO a log in VHF oppure UHF; consentito n°1 QSO via ripetitore in banda VHF oppure UHF.</li>
                <li>4) Modi operativi consentiti: FM, C4FM, DMR, D-STAR.</li>
                <li>5) Bande operative: 6mt, 2mt, 70cm, 23cm.</li>
                <li>6) Il punteggio è calcolato 1 punto al chilometro (Es: 100 km di Qrb=100 punti).</li>
                <li>7) Il punteggio dei cacciatori è calcolato 1 punto per ogni Qso concluso.</li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default RegolamentoRadio;