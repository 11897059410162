import React, { useState, useEffect } from 'react';
import { Alert, Container, Form, InputGroup,  Row, Col, Table,  Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FiX } from 'react-icons/fi';
import { FaHome, FaSignOutAlt, FaListAlt, FaTrophy, FaBook, FaEdit, FaMapMarkedAlt, FaUserTie } from 'react-icons/fa';
import './GestioneAttivita.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { resetUser } from './store';

function Qsl() {
    const [attivita, setAttivita] = useState([]);
    const [filtroReferenza, setFiltroReferenza] = useState('');
    const [filtroData, setFiltroData] = useState('');

    const [attivitaFiltrate, setAttivitaFiltrate] = useState([]);
    const totaleRighe = attivitaFiltrate.length;
    const totaleQRB = attivitaFiltrate.reduce((acc, curr) => acc + curr.qrbTotale, 0);
    const navigate = useNavigate();
    const nominativo = sessionStorage.getItem('nominativo');

    const [showAlertNoFoto, setShowAlertNoFoto] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchAttivita();
    }, []);

    const handleResetDataFilter = () => {
        setFiltroData(''); // Resetta il filtroData a una stringa vuota
    };

    useEffect(() => {
        // Aggiorna il filtro ogni volta che i dati delle attività o i valori dei filtri cambiano
        const risultatiFiltrati = attivita.filter(a => {
            const filtroReferenzaValido = filtroReferenza ? a.referenza.toLowerCase().includes(filtroReferenza.toLowerCase()) : true;

            let filtroDataValido = true;
            if (filtroData) {
                // Converte la data dal formato DD-MM-YYYY al formato YYYY-MM-DD per il confronto
                const [giorno, mese, anno] = filtroData.split("-");
                const dataFormatoJS = `${anno}-${mese}-${giorno}`;
                filtroDataValido = a.data === dataFormatoJS;
            }

            return filtroReferenzaValido && filtroDataValido;
        });
        setAttivitaFiltrate(risultatiFiltrati);
    }, [filtroReferenza, filtroData, attivita]);

    const fetchAttivita = async () => {
        const userUuid = sessionStorage.getItem('userUUID');
        const basicAuth = 'Basic ' + btoa("FmMontano:FmMontano24");
        const response = await fetch('/fmMontano/trova-attivita', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': basicAuth,
            },
            body: JSON.stringify(userUuid),
        });

        if (response.ok) {
            const datiAttivita = await response.json();
            setAttivita(datiAttivita);
        } else {
            // Gestisci l'errore
            console.error("Errore nel recupero delle attività");
        }
    };

    const handleLogout = () => {
        sessionStorage.clear();
        dispatch(resetUser());
        navigate('/login');
    };

    const handleModifica = (id) => {
        const attivitaDaModificare = attivita.find(a => a.id === id);

        attivitaDaModificare.data = moment(attivitaDaModificare.data, "DD-MM-YYYY").format("YYYY-MM-DD");
        navigate('/qso_elenco', { state: { attivita: attivitaDaModificare } });
    };

    return (
        <>
            <Navbar fixed="top" expand="false" style={{
                borderBottom: '1px solid #dee2e6',
                backgroundColor: 'rgba(255, 255, 255, 0.7)', // Uguale al contenitore del messaggio
                backdropFilter: 'blur(5px)', // Opzionale: mantiene l'effetto sfocatura
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Consistente con lo stile del contenitore del messaggio
            }}>
                <Container>
                    <Navbar.Brand href="#" style={{ color: 'black', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        <img src="/85a19cf2-a898-4560-b35c-17f9dd5cf238.png" alt="FM Montano Logo" style={{ height: '40px', marginRight: '10px' }} />
                        FM Montano
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'black' }} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link onClick={() => navigate('/regolamento')} style={{ color: 'black' }}>
                                <FaBook /> Regolamento
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/elenco-attivita')} style={{ color: 'black' }}>
                                <FaListAlt /> Elenco Attività
                            </Nav.Link>

                            <Nav.Link onClick={() => navigate('/gestione-attivita')} style={{ color: 'black' }}>
                             <FaEdit /> Gestione Attività
                            </Nav.Link>

                            <Nav.Link onClick={() => navigate('/mappa')} style={{ color: 'black' }}>
                            <FaMapMarkedAlt /> Mappa Referenze
                             </Nav.Link>
                            <NavDropdown title={<span><FaTrophy /> Classifiche Storiche</span>} id="basic-nav-dropdown" style={{ color: 'black' }}>
                                <NavDropdown.Item onClick={() => navigate('/classifica-qrb')}>Classifica Storica QRB Max</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/classifica-punti-storica')}>Classifica Storica punti</NavDropdown.Item>
                            </NavDropdown>
                            {/* Dropdown per le classifiche */}
                            <NavDropdown title={<span><FaTrophy /> Classifiche</span>} id="basic-nav-dropdown" style={{ color: 'black' }}>
                                <NavDropdown.Item onClick={() => navigate('/classifica-attivita')}>Classifica Attivatori 2024</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/classifica-cacciatori-2024')}>Classifica Cacciatori 2024</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link onClick={() => navigate('/direttivo')} style={{ color: 'black' }}>
                <FaUserTie /> Direttivo
              </Nav.Link>
                            <Nav.Link onClick={() => navigate('/home')} style={{ color: 'black' }}>
                                <FaHome /> Home
                            </Nav.Link>

                            <Nav.Link onClick={handleLogout} style={{ color: 'black', marginLeft: '20px' }}>
                                <FaSignOutAlt /> Logout
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div
                className="d-flex align-items-center justify-content-center"
                style={{
                    minHeight: "95vh",
                    backgroundImage: 'url("/login.webp")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100vw',
                    paddingTop: '56px' // Aggiungi un padding top per compensare l'altezza della navbar fissa
                }}
            >

                <Container>
                    {showAlertNoFoto && (
                        <Alert variant="warning" onClose={() => setShowAlertNoFoto(false)} dismissible>
                            Non ci sono foto disponibili per questa attività.
                        </Alert>
                    )}
                    <h2 style={{ color: 'red', textShadow: '2px 2px 4px #ffffff' }}>Seleziona l'attività per inviare la QSL</h2>
                    <Row className="mb-3">
                        <Col>
                            <Form.Control
                                type="text"
                                placeholder="Filtra per Referenza"
                                value={filtroReferenza}
                                onChange={e => setFiltroReferenza(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <InputGroup>
                                <Form.Control
                                    type="date"
                                    value={filtroData}
                                    onChange={e => setFiltroData(e.target.value)}
                                />
                                {filtroData && (
                                    <InputGroup.Text onClick={handleResetDataFilter} style={{ cursor: 'pointer', border: 'none', background: 'transparent' }}>
                                        <FiX color="white" />
                                    </InputGroup.Text>
                                )}
                            </InputGroup>
                        </Col>
                    </Row>
                    <div className="container-top">
                        <Row>
                            <Col className="text-center">
                                <strong>Nominativo:</strong> <strong style={{ color: 'black' }}>{nominativo}</strong>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-center">
                                    <strong>Numero di Attività filtrate:</strong> {totaleRighe}
                                </div>
                            </Col>
                            <Col>
                                <div className="text-center">
                                    <strong>Totale QRB:</strong> {totaleQRB} km
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Attivatore</th>
                                <th>Referenza</th>
                                <th>Punti</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attivitaFiltrate.map((a, index) => (
                                <tr key={index} onClick={() => handleModifica(a.id)} style={{ cursor: 'pointer' }}>
                                    <td>{a.attivatore}</td>
                                    <td>{a.referenza}</td>
                                    <td>{a.punti.toLocaleString()}</td>
                                    <td>{a.data}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>



                </Container>
            </div>
        </>
    );
}

export default Qsl;