// RegisterComponent.js
import React, { useState } from 'react';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function RegisterComponent() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [nominativo, setNominativo] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleRegister = async (e) => {
        e.preventDefault();
        setSubmitted(true);
    
        // Rimuovi gli spazi dal nominativo
        const nominativoNoSpaces = nominativo.replace(/\s+/g, '');
    
        if (!email || !password || !nominativoNoSpaces || password !== confirmPassword) {
            setError('Per favore, inserisci tutti i campi richiesti e assicurati che le password corrispondano.');
            return;
        }
    
        if (nominativoNoSpaces.length > 10) {
            setError('Il nominativo non può superare i 10 caratteri.');
            return;
        }
        setIsLoading(true); // Inizio del caricamento
    
        try {
            const basicAuth = 'Basic ' + btoa("FmMontano:FmMontano24");
    
            const response = await fetch('/fmMontano/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': basicAuth,
                },
                body: JSON.stringify({ email, password, nominativo: nominativoNoSpaces }),
            });
    
            if (response.ok) {
                setIsLoading(false); // Fine del caricamento
                setShowSuccess(true);
    
            } else {
                setIsLoading(false);
                const errorData = await response.text();
                console.error('Errore durante la registrazione:', errorData);
                setError(errorData);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Errore di rete:', error);
            setError('Errore di rete durante la registrazione.');
        }
    };

    const handleCloseModal = () => {
        setShowSuccess(false); // Chiude la modale
        navigate('/login');
    };

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{
                    minHeight: "95vh",
                    backgroundImage: 'url("/login.webp")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100vw',
                }}
            >
                <div className="w-100" style={{ maxWidth: "400px", backgroundColor: "rgba(192, 192, 192, 0.7)", padding: "20px", borderRadius: "15px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
                    <h2 className="text-center mb-4" style={{ color: "black" }}>Registrazione</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleRegister}>


                        <Form.Group id="name">
                            <Form.Label>Nominativo</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                value={nominativo} // Imposta il valore dallo stato
                                isInvalid={submitted && !nominativo}
                                onChange={(e) => setNominativo(e.target.value.toUpperCase())} // Aggiorna lo stato con il valore in maiuscolo
                            />
                            <Form.Control.Feedback type="invalid">
                                Per favore inserisci il tuo Nominativo completo.
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                required
                                isInvalid={submitted && !email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Per favore inserisci un'email valida.
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                isInvalid={submitted && !password}
                                onChange={(e) => setPassword(e.target.value)}
                                onPaste={(e) => e.preventDefault()}
                                onCopy={(e) => e.preventDefault()}
                            />
                            <Form.Control.Feedback type="invalid">
                                Per favore inserisci una password.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group id="confirmPassword">
                            <Form.Label>Conferma Password</Form.Label>
                            <Form.Control
                                type="password"
                                required
                                isInvalid={submitted && password !== confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                onPaste={(e) => e.preventDefault()}
                                onCopy={(e) => e.preventDefault()}
                            />
                            <Form.Control.Feedback type="invalid">
                                Le password non corrispondono.
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Button className="w-100 mt-3" type="submit" disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                                    Caricamento...
                                </>
                            ) : 'Registrati'}
                        </Button>

                    </Form>
                    <Button variant="dark" className="mt-3 w-100" onClick={() => navigate('/')}>
                        <FontAwesomeIcon icon={faArrowCircleLeft} /> Torna alla Home
                    </Button>

                </div>
            </div>

            <Modal show={showSuccess} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>🎉 Complimenti!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Il tuo account è stato creato con successo! 🚀<br /><br />
                    Per completare la registrazione ti abbiamo inviato un'email. Segui il link al suo interno per attivare il tuo account.<br /><br />
                    💌 <em>Controlla la tua casella di posta</em> e clicca sul link che troverai nel messaggio che ti abbiamo inviato.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}


export default RegisterComponent;
