import React, { useState } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { FaPlus, FaBook, FaEdit, FaSignOutAlt, FaListAlt, FaTrophy, FaUserTie } from 'react-icons/fa';
import { resetUser } from './store';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NewActivityModalPmr from './NewActivityModalPmr';

function HomePmr() {
  const nominativo = sessionStorage.getItem('nominativo');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleLogout = () => {
    sessionStorage.clear();
    dispatch(resetUser());
    navigate('/loginPmr');
  };

  return (
    <>
      <Navbar fixed="top" expand="false" style={{
        borderBottom: '1px solid #dee2e6',
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Uguale al contenitore del messaggio
        backdropFilter: 'blur(5px)', // Opzionale: mantiene l'effetto sfocatura
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Consistente con lo stile del contenitore del messaggio
      }}>
        <Container>
          <Navbar.Brand href="#" style={{ color: 'black', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
          <img src="/pmr.png" alt="Pmr Logo" style={{ height: '40px', marginRight: '10px' }} />
            Pmr Montano
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'black' }} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => navigate('/regolamentoPmr')} style={{ color: 'black' }}>
                <FaBook /> Regolamento
              </Nav.Link>
              <Nav.Link onClick={handleShowModal} style={{ color: 'black' }}>
                <FaPlus /> Inserisci Nuova Attività
              </Nav.Link>
              <Nav.Link onClick={() => navigate('/gestione-attivitaPmr')} style={{ color: 'black' }}>
                <FaEdit /> Gestione Attività
              </Nav.Link>
             
              <Nav.Link onClick={() => navigate('/elenco-attivitaPmr')} style={{ color: 'black' }}>
                <FaListAlt /> Elenco Attività
              </Nav.Link>

              {/* Dropdown per le classifiche */}
              <NavDropdown title={<span><FaTrophy /> Classifiche</span>} id="basic-nav-dropdown" style={{ color: 'black' }}>
                <NavDropdown.Item onClick={() => navigate('/classifica-attivitaPmr')}>Classifica Attivatori 2024</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/classifica-cacciatori-2024Pmr')}>Classifica Cacciatori 2024</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link onClick={() => navigate('/direttivoPmr')} style={{ color: 'black' }}>
                <FaUserTie /> Direttivo
              </Nav.Link>


              <Nav.Link onClick={handleLogout} style={{ color: 'black', marginLeft: '20px' }}>
                <FaSignOutAlt /> Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "95vh",
          backgroundImage: 'url("/login.webp")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
        }}
      >
        <Container style={{ padding: 0, maxWidth: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <div style={{
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            padding: '10px',
            borderRadius: '10px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          }}>
            <h1 style={{ color: 'black', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
              {nominativo ? `Benvenuto nella Home Page, ${nominativo}!` : 'Benvenuto nella Home Page!'}
            </h1>
          </div>
        </Container>
      </div>
      <NewActivityModalPmr show={showModal} handleClose={handleCloseModal} nominativo={nominativo} />
    </>
  );
}

export default HomePmr;
