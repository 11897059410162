// src/resizeImage.js
import { readAndCompressImage } from 'browser-image-resizer';

const resizeImageSettings = {
    quality: 0.7,
    maxWidth: 800,
    maxHeight: 800,
    autoRotate: true,
};

export async function resizeImage({ file, maxSize }) {
    try {
        const resizedImage = await readAndCompressImage(file, {
            ...resizeImageSettings,
            maxWidth: maxSize,
            maxHeight: maxSize,
        });
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(resizedImage);
        });
    } catch (error) {
        console.error('Errore nel ridimensionamento dell\'immagine:', error);
        throw error;
    }
}
