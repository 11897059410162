import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function PreLogin() {
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    navigate('/login');
  };

  const handleGoToLoginPmr = () => {
    navigate('/loginPmr');
  };
  
  return (
    <div
      className="d-flex align-items-center justify-content-center p-3"
      style={{
        minHeight: "95vh",
        backgroundImage: 'url("/login.webp")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        overflow: 'hidden' // Previene lo scrolling verticale esterno
      }}
    >
      <div className="text-center" style={{ backgroundColor: "rgba(192, 192, 192, 0.7)", padding: "20px", borderRadius: "15px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", maxWidth: "600px" }}>
        <img src="/85a19cf2-a898-4560-b35c-17f9dd5cf238.png" alt="FM Montano Logo" style={{ maxWidth: '150px', marginBottom: '1rem' }} />
        <h2 style={{ color: 'red', fontSize: '15px' }}>FM Montano</h2>
        <p style={{ color: 'black', fontSize: '12px' }}>
          ATTIVITÀ RADIO FM VHF/UHF CHE IN AMBITO ESCURSIONISTICO/ALPINISTICO E PIÙ PRECISAMENTE DALLE CIME REFERENZIATE ELENCATE SU APPOSITA LISTA, PREVEDE L’UTILIZZO DI APPARATI PALMARI A SCOPO DI DX E SICUREZZA PERSONALE.
        </p>
        <img src="/c1248ddd-5064-4e70-8c13-4d8b7af0a65e.png" alt="Radiomobile Logo" style={{ maxWidth: '150px', marginBottom: '1rem' }} />
        <h2 style={{ color: 'red', fontSize: '15px' }}>Radiomobile</h2>
        <p style={{ color: 'black', fontSize: '12px' }}>
          ATTIVITÀ RADIO FM VHF/UHF MEDIANTE DIVERSE TIPOLOGIE DI APPARATI EFFETTUATA DAI CHECK POINT (PUNTI TATTICI DI COLLEGAMENTO) ELENCATI SU APPOSITA LISTA.
        </p>
        <Button variant="primary" onClick={handleGoToLogin} className="mt-3" style={{ fontSize: '16px' }}>
        Login Fm Montano
        </Button>
        <div style={{ marginTop: '1rem' }}>
        <img src="/pmr.png" alt="Pmr Logo" style={{ maxWidth: '150px', marginBottom: '1rem' }} />
          <h2 style={{ color: 'red', fontSize: '15px' }}>PMR Montano</h2>
          <p style={{ color: 'black', fontSize: '12px' }}>
            ATTIVITÀ PMR 446 FM DALLE REFERENZE PRESENTI NEI DUE PROGRAMMI FMMONTANO E RADIOMOBILE MEDIANTE APPARATI OMOLOGATI E ORIGINALI. PMR MONTANO È IL PRIMO PROGRAMMA IN ITALIA DEDICATO ALL'ATTIVITÀ RADIO IN QUOTA GESTITO DA UN SISTEMA A CLASSIFICA. CANALI DI RIFERIMENTO: CH4 E CH7
          </p>
          <Button variant="primary" onClick={handleGoToLoginPmr} className="mt-3" style={{ fontSize: '16px' }}>
          Login Pmr Montano
        </Button>
        </div>
      </div>
    </div>
  );
}

export default PreLogin;
