import React from 'react';
import html2canvas from 'html2canvas';
import { useLocation, useNavigate } from 'react-router-dom';

function QsoImageDownload() {
    const location = useLocation();
    const navigate = useNavigate();
    const qso = location.state?.qso;

    const handleIndietro = () => {
        navigate('/qsl');
    };

    const handleDownload = () => {
        const element = document.querySelector('.capture-container'); // Modifica il selettore per catturare l'elemento corretto
        if (element) {
            html2canvas(element, {
                useCORS: true,
                scale: 2, // Riduce la scala per diminuire la risoluzione e il carico di memoria
                logging: true, // Abilita i log per un debugging più dettagliato
                windowWidth: document.documentElement.offsetWidth, // Forza `html2canvas` a considerare la larghezza corrente del viewport
            }).then((canvas) => {
                const image = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = image;
                link.download = `QSL_${qso.cacciatore}_${qso.data.replace(/\//g, '-')}.png`;
                link.click();
            });
        }
    };

    if (!qso) return <p>Caricamento...</p>;

    return (
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <div className="capture-container" style={{ position: 'relative', width: '100%', height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src="/qsl.webp" alt="Background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                <div className="text-overlay" style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', textAlign: 'center', width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h1 style={{ color: 'red' }}>Attivatore: {qso?.attivatore}</h1>
                        <h1>Cacciatore: {qso?.cacciatore}</h1>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p>Nome: {qso?.nome_fmm}</p>
                        <p>Locatore: {qso?.locator_fmm}</p>
                        <p>Referenza: {qso?.referenza}</p>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p>Data: {qso?.data}</p>
                        <p>Modo: {qso?.modo}</p>
                        <p>Frequenza: {qso?.frequenza}</p>
                        <p>RST: 59</p>
                    </div>
                    <img src="/85a19cf2-a898-4560-b35c-17f9dd5cf238.png" alt="FM Montano Logo" style={{ height: '80px' }} />
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
            <button className="btn btn-primary mx-3" onClick={handleDownload}>Scarica QSL</button>
            <button className="btn btn-primary mx-3" onClick={handleIndietro}>Scegli un altro Qso</button>
        </div>
            
        </div>
    );
}

export default QsoImageDownload;