import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { store } from './store'; // Assicurati che il percorso sia corretto

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// Se vuoi iniziare a misurare le prestazioni nella tua app, passa una funzione
// per registrare i risultati (ad esempio: reportWebVitals(console.log))
// o invia a un endpoint di analisi. Scopri di più: https://bit.ly/CRA-vitals
reportWebVitals();