import { configureStore, createSlice } from '@reduxjs/toolkit';

// Definizione dello slice per l'utente
const userSlice = createSlice({
  name: 'user',
  initialState: {
    uuid: null,
    nominativo: null,
  },
  reducers: {
    setUserUUID: (state, action) => {
      state.uuid = action.payload;
    },

    setNominativo: (state, action) => { // Definizione del reducer per il nominativo
        state.nominativo = action.payload;
      },

      resetUser: (state) => {
        state.uuid = null;
        state.nominativo = null;
      },
  },
});

export const { setUserUUID, setNominativo, resetUser } = userSlice.actions;

// Configurazione dello store Redux
export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
  },
});

