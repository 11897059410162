import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Assicurati di includere il CSS di Bootstrap
import './EmailImage.css'; // Assicurati di avere questo file CSS come descritto precedentemente

function EmailImageRadio() {
    const location = useLocation();
    const attivita = location.state?.attivita;
    const [qsoList, setQsoList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (attivita) {
            const fetchQSO = async () => {
                const basicAuth = 'Basic ' + btoa("FmMontano:FmMontano24");
                const requestBody = {
                    uuid: attivita.uuid,
                    idAttivita: attivita.id
                };

                try {
                    const response = await fetch('/fmMontano/trova-qso_radio', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': basicAuth,
                        },
                        body: JSON.stringify(requestBody),
                    });

                    if (response.ok) {
                        const qsoData = await response.json();
                        // Aggiungi i campi nome_fmm e locator_fmm a ogni QSO
                        const enrichedQsoData = qsoData.map(qso => ({
                            ...qso,
                            nome_fmm: attivita.nome_fmm,
                            locator_fmm: attivita.locator_fmm
                        }));
                        console.log(attivita); // Se vuoi vedere i dettagli dell'attività nel log
                        setQsoList(enrichedQsoData);
                    } else {
                        throw new Error('Errore nel recupero dei QSO');
                    }
                } catch (error) {
                    console.error("Errore nella chiamata fetchQSO:", error);
                }
            };

            fetchQSO();
        }
    }, [attivita]);

    const handleSelectQso = (qso) => {
        navigate('/email-image_radio', { state: { qso } });
    };

    return (
        <div className="d-flex align-items-center justify-content-center"
            style={{
                backgroundImage: 'url("/login_radio.webp")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100vw',
            }}>
            <div className="container bg-light p-4 shadow" style={{ maxWidth: '100%', overflowX: 'auto', borderRadius: '15px' }}>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Attivatore</th>
                            <th>Cacciatore</th>
                            <th>Data</th>
                            <th>Modo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {qsoList.map(qso => (
                            <tr key={qso.id} onClick={() => handleSelectQso(qso)} style={{ cursor: 'pointer' }}>
                                <td>{qso.attivatore}</td>
                                <td>{qso.cacciatore}</td>
                                <td>{qso.data}</td>
                                <td>{qso.modo}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default EmailImageRadio;