import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Navbar, Nav, Container, NavDropdown, Button, Form } from 'react-bootstrap';
import { FaBook, FaEdit, FaSignOutAlt, FaListAlt, FaTrophy, FaSearchPlus, FaHome, FaUserTie } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetUser } from './store';

const customIcon = new L.Icon({
    iconUrl: '/marker.svg', // Percorso diretto all'icona nella cartella public
    iconSize: [15, 25], // Nuove dimensioni ridotte dell'icona
    iconAnchor: [7.5, 25], // Ancora al centro inferiore dell'icona
    popupAnchor: [0, -25] // Popup spunta dal punto più alto dell'icona
});

const currentLocationIcon = new L.Icon({
    iconUrl: '/here.svg', // Percorso alla nuova icona rossa
    iconSize: [15, 25], // Nuove dimensioni ridotte dell'icona
    iconAnchor: [7.5, 25], // Ancora al centro inferiore dell'icona
    popupAnchor: [0, -25] // Popup spunta dal punto più alto dell'icona
});

function LocationMarker() {
    const [position, setPosition] = useState(null);
    const map = useMap();

    useEffect(() => {
        map.locate().on("locationfound", function (e) {
            setPosition(e.latlng);
            map.flyTo(e.latlng, map.getZoom());
        });
    }, [map]);

    return position === null ? null : (
        <Marker position={position} icon={currentLocationIcon}>
            <Popup>You are here</Popup>
        </Marker>
    );
}

function MappaReferenzeRadio() {
    const [referenze, setReferenze] = useState([]);
    const mapRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [filtro, setFiltro] = useState("");

    useEffect(() => {
        const fetchReferenze = async () => {
            const basicAuth = 'Basic ' + btoa("FmMontano:FmMontano24");
            const response = await fetch('/fmMontano/cpref', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': basicAuth,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setReferenze(data);
            } else {
                console.error("Errore nel caricamento delle referenze");
            }
        };

        fetchReferenze();
    }, []);

    const handleZoomToMarker = useCallback((lat, lng) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (mapRef.current) {
            mapRef.current.flyTo([lat, lng], 15, { animate: false });
        }
    }, []);

    const handleLogout = () => {
        sessionStorage.clear();
        dispatch(resetUser());
        navigate('/login');
    };

    return (
        <>
            <Navbar fixed="top" expand="false" style={{
                borderBottom: '1px solid #dee2e6',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(5px)',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
                <Container>
                    <Navbar.Brand href="#" style={{ color: 'black', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        <img src="/c1248ddd-5064-4e70-8c13-4d8b7af0a65e.png" alt="FM Montano Logo" style={{ height: '40px', marginRight: '10px' }} />
                        RadioMobile
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'black' }} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link onClick={() => navigate('/regolamento_radio')} style={{ color: 'black' }}>
                                <FaBook /> Regolamento
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/gestione-attivita_radio')} style={{ color: 'black' }}>
                                <FaEdit /> Gestione Attività
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/elenco-attivita_radio')} style={{ color: 'black' }}>
                                <FaListAlt /> Elenco Attività
                            </Nav.Link>

                            {/* Dropdown per le classifiche */}
                            <NavDropdown title={<span><FaTrophy /> Classifiche</span>} id="basic-nav-dropdown" style={{ color: 'black' }}>
                                <NavDropdown.Item onClick={() => navigate('/classifica-attivita_radio')}>Classifica Attivatori 2024</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/classifica-cacciatori-2024_radio')}>Classifica Cacciatori 2024</NavDropdown.Item>
                            </NavDropdown>

                            <Nav.Link onClick={() => navigate('/direttivo_radio')} style={{ color: 'black' }}>
                                <FaUserTie /> Direttivo
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/home_radio')} style={{ color: 'black' }}>
                                <FaHome /> Home
                            </Nav.Link>

                            <Nav.Link onClick={handleLogout} style={{ color: 'black', marginLeft: '20px' }}>
                                <FaSignOutAlt /> Logout
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <MapContainer center={[44.391253, 8.978014]} zoom={13} style={{ height: '70vh', width: '100%', marginTop: '60px' }} ref={mapRef} minZoom={10} maxZoom={18}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <LocationMarker />
                {referenze.map((ref) => (
                    <Marker
                        key={ref.idCp}
                        position={[ref.latCp, ref.lonCp]}
                        icon={customIcon}
                    >
                        <Popup>{ref.descrCp} ({ref.nomeCp})</Popup>
                    </Marker>
                ))}
            </MapContainer>
            <div style={{ marginTop: '20px', overflowY: 'auto' }}>
                <Form.Control
                    type="text"
                    placeholder="Cerca..."
                    value={filtro}
                    onChange={e => setFiltro(e.target.value)}
                    style={{ marginBottom: '10px' }}
                />
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th>Zoom</th>
                        </tr>
                    </thead>
                    <tbody>
                        {referenze.filter(ref => ref.descrCp.toLowerCase().includes(filtro.toLowerCase()) || ref.nomeCp.toLowerCase().includes(filtro.toLowerCase())).map(ref => (
                            <tr key={ref.idCp}>
                                <td>{ref.nomeCp}</td>
                                <td>{ref.descrCp}</td>
                                <td><Button variant="link" onClick={() => handleZoomToMarker(ref.latCp, ref.lonCp)}><FaSearchPlus color="white" /></Button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default MappaReferenzeRadio;