import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

function NewActivityModalPmr({ show, handleClose, nominativo }) {
    const navigate = useNavigate();

    const [opzioni, setOpzioni] = useState([]);

    const [selectedOption, setSelectedOption] = useState(null);
    const [data, setData] = useState(() => {
        const today = moment().format('YYYY-MM-DD');
        return today;
    });

    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const fetchOpzioni = async () => {
            const basicAuth = 'Basic ' + btoa("FmMontano:FmMontano24");
            const response = await fetch('/fmMontano/pmrRef', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': basicAuth,
                },
            });

            if (response.ok) {
                const data = await response.json();
                const opzioniMappate = data.map(item => ({
                    value: item.nome,
                    label: `${item.nome} - ${item.descr} - ${item.locator}`,
                    type: item.tipo,
                }));
                setOpzioni(opzioniMappate);
            } else {
                console.error("Errore nel caricamento delle opzioni");
            }
        };

        fetchOpzioni();
    }, []);

    const handleProseguiClick = () => {
        if (selectedOption) {
            const { label} = selectedOption;
            const [nome, descr, locator] = label.split(' - ');

            navigate('/pmrAttivita', {
                state: {
                    nome,
                    descr,
                    locator,
                    data,
                    nominativo,
                }
            });
        } else {
            setShowAlert(true);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Inserisci Nuova Attività</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} controlId="formNominativo" className="mb-3">
                        <Form.Label column sm="4">Nominativo</Form.Label>
                        <Col sm="8">
                            <Form.Control type="text" value={nominativo} readOnly />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formOpzioni" className="mb-3">
                        <Form.Label column sm="4">Referenza</Form.Label>
                        <Col sm="8">
                            <Select
                                options={opzioni}
                                onChange={setSelectedOption}
                                value={selectedOption}
                                isSearchable={true}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formData" className="mb-3">
                        <Form.Label column sm="4">Data</Form.Label>
                        <Col sm="8">
                            <Form.Control
                                type="date"
                                value={data}
                                onChange={(e) => setData(e.target.value)}
                            />
                        </Col>
                    </Form.Group> 

                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Chiudi
                </Button>
                <Button variant="primary" onClick={handleProseguiClick}>
                    Prosegui
                </Button>
            </Modal.Footer>
            <Alert show={showAlert} variant="danger" onClose={() => setShowAlert(false)} dismissible>
                Devi selezionare un'opzione prima di proseguire
            </Alert>
        </Modal>
    );
}

export default NewActivityModalPmr;
