import React from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { FaHome, FaEdit, FaSignOutAlt, FaListAlt, FaTrophy, FaMapMarkedAlt, FaUserTie } from 'react-icons/fa';
import { resetUser } from './store';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function Regolamento() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    dispatch(resetUser());
    navigate('/login');
  };

  return (
    <>
      <Navbar fixed="top" expand="false" style={{
        borderBottom: '1px solid #dee2e6',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(5px)',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}>
        <Container>
        <Navbar.Brand href="#" style={{ color: 'black', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
            <img src="/85a19cf2-a898-4560-b35c-17f9dd5cf238.png" alt="FM Montano Logo" style={{ height: '40px', marginRight: '10px' }} />
            FM Montano
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'black' }} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => navigate('/gestione-attivita')} style={{ color: 'black' }}>
                <FaEdit /> Gestione Attività
              </Nav.Link>
              <Nav.Link onClick={() => navigate('/elenco-attivita')} style={{ color: 'black' }}>
                <FaListAlt /> Elenco Attività
              </Nav.Link>
              <Nav.Link onClick={() => navigate('/mappa')} style={{ color: 'black' }}>
              <FaMapMarkedAlt /> Mappa Referenze
              </Nav.Link>
              <NavDropdown title={<span><FaTrophy /> Classifiche Storiche</span>} id="basic-nav-dropdown" style={{ color: 'black' }}>
                <NavDropdown.Item onClick={() => navigate('/classifica-qrb')}>Classifica Storica QRB Max</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/classifica-punti-storica')}>Classifica Storica punti</NavDropdown.Item>
              </NavDropdown>

              {/* Dropdown per le classifiche */}
              <NavDropdown title={<span><FaTrophy /> Classifiche</span>} id="basic-nav-dropdown" style={{ color: 'black' }}>
                <NavDropdown.Item onClick={() => navigate('/classifica-attivita')}>Classifica Attivatori 2024</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/classifica-cacciatori-2024')}>Classifica Cacciatori 2024</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link onClick={() => navigate('/direttivo')} style={{ color: 'black' }}>
                <FaUserTie /> Direttivo
              </Nav.Link>
              <Nav.Link onClick={() => navigate('/home')} style={{ color: 'black' }}>
                <FaHome /> Home
              </Nav.Link>
              <Nav.Link onClick={handleLogout} style={{ color: 'black', marginLeft: '20px' }}>
                <FaSignOutAlt /> Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "95vh",
          backgroundImage: 'url("/login.webp")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          paddingTop: '56px'
        }}
      >
        <Container style={{
          padding: 0, 
          maxWidth: "100%", 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          height: '100%'
        }}>
          <div className="text-center" style={{
            backgroundColor: "rgba(192, 192, 192, 0.7)", 
            padding: "20px", 
            borderRadius: "15px", 
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", 
            maxWidth: "600px"
          }}>
            <img src="/85a19cf2-a898-4560-b35c-17f9dd5cf238.png" alt="FM Montano Logo" style={{ maxWidth: '150px', marginBottom: '1rem' }} />
            <h2 style={{ color: 'red', fontSize: '20px' }}>Regolamento</h2>
            <div style={{ textAlign: 'left', lineHeight: '1.6', fontSize: '16px', color: 'black' }}>
              E’ ritenuta valida un’attivazione che rispetta i seguenti requisiti:
              <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
              <li>1) Almeno 200 mt. di dislivello dal punto di partenza e 30 minuti di cammino. È consentito l'uso di biciclette muscolari oppure a pedalata assistita in modalità Eco.</li>
                <li>2) Potenza max di emissione: 5 watt (QRP).</li>
                <li>3) Attività da una delle referenze presenti su lista cime FMMontano.</li>
                <li>4) I QSO dovranno pervenire sull'apposita piattaforma di caricamento nonché sito web www.fmmontano.com.</li>
                <li>5) Almeno n°1 QSO a log in VHF oppure UHF; consentito n°1 QSO via ripetitore in banda VHF oppure UHF.</li>
                <li>6) Modi operativi consentiti: FM – C4FM – DMR – DSTAR.</li>
                <li>7) Bande operative: 10mt- 6mt- 2mt – 70cm – 23cm.</li>
                <li>8) Il punteggio è calcolato 1 punto al chilometro (Es: 100 km di QRB = 100 punti). Ogni QSO effettuato in Uhf ( 70 cm e 23 cm) sarà calcolato 1,3 punti al chilometro.</li>
                <li>9) Il punteggio dei cacciatori è calcolato 1 punto per ogni QSO concluso.</li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Regolamento;
