import React, { useState, useEffect } from 'react';
import { Alert, Container, Form, InputGroup, Modal, Row, Col, Table, Button, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FiX, FiImage } from 'react-icons/fi';
import { FaEdit, FaSignOutAlt, FaTrophy, FaHome, FaBook, FaMapMarkedAlt, FaUserTie } from 'react-icons/fa';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetUser } from './store';
import './ElencoAttività.css';

function ElencoAttivita() {
    const [attivita, setAttivita] = useState([]);
    const [filtroReferenza, setFiltroReferenza] = useState('');
    const [filtroData, setFiltroData] = useState('');
    const [filtroNomeFmm, setFiltroNomeFmm] = useState('');
    const [filtroNominativo, setFiltroNominativo] = useState('');

    const [attivitaFiltrate, setAttivitaFiltrate] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [attivitaSelezionata, setAttivitaSelezionata] = useState(null);

    const [showAlertNoFoto, setShowAlertNoFoto] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const nominativo = sessionStorage.getItem('nominativo');

    useEffect(() => {
        fetchAttivita();
    }, []);

    const handleVisualizzaFoto = async (attivitaSelezionata) => {
        setAttivitaSelezionata(attivitaSelezionata); // Salva l'intera attività selezionata
        setShowModal(true);

        // Esegui la chiamata al backend per recuperare i QSO per l'attività selezionata
        const basicAuth = 'Basic ' + btoa("FmMontano:FmMontano24");
        try {
            const response = await fetch(`/fmMontano/trova-qso-perAttivita/${attivitaSelezionata.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': basicAuth,
                },
            });

            if (response.ok) {
                const datiQSO = await response.json();
                setAttivitaSelezionata({ ...attivitaSelezionata, qso: datiQSO });
            } else {
                console.error("Errore nel recupero dei QSO");
            }
        } catch (error) {
            console.error("Errore di connessione al servizio di recupero QSO", error);
        }
    };

    const handleResetDataFilter = () => {
        setFiltroData(''); // Resetta il filtroData a una stringa vuota
    };

    useEffect(() => {
        // Logica di filtraggio estesa
        const risultatiFiltrati = attivita.filter(a => {
            const filtroReferenzaValido = filtroReferenza ? a.referenza.toLowerCase().includes(filtroReferenza.toLowerCase()) : true;
            const filtroNomeFmmValido = filtroNomeFmm ? a.nome_fmm.toLowerCase().includes(filtroNomeFmm.toLowerCase()) : true;
            const filtroNominativoValido = filtroNominativo ? a.attivatore.toLowerCase().includes(filtroNominativo.toLowerCase()) : true;

            let filtroDataValido = true;
            if (filtroData) {
                const dataFormatoJS = filtroData.split("-").reverse().join("-");
                filtroDataValido = a.data === dataFormatoJS;
            }

            return filtroReferenzaValido && filtroNomeFmmValido && filtroNominativoValido && filtroDataValido;
        });
        setAttivitaFiltrate(risultatiFiltrati);
    }, [filtroReferenza, filtroNomeFmm, filtroNominativo, filtroData, attivita]);

    const fetchAttivita = async () => {
        const basicAuth = 'Basic ' + btoa("FmMontano:FmMontano24");
        const response = await fetch('/fmMontano/trova-tutte_attivita', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': basicAuth,
            },
        });

        if (response.ok) {
            const datiAttivita = await response.json();
            setAttivita(datiAttivita);
        } else {
            // Gestisci l'errore
            console.error("Errore nel recupero delle attività");
        }
    };

    const handleLogout = () => {
        sessionStorage.clear();
        dispatch(resetUser());
        navigate('/login');
    };

    return (
        <>
            <Navbar fixed="top" expand="false" style={{
                borderBottom: '1px solid #dee2e6',
                backgroundColor: 'rgba(255, 255, 255, 0.7)', // Uguale al contenitore del messaggio
                backdropFilter: 'blur(5px)', // Opzionale: mantiene l'effetto sfocatura
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Consistente con lo stile del contenitore del messaggio
            }}>
                <Container>
                <Navbar.Brand href="#" style={{ color: 'black', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        <img src="/85a19cf2-a898-4560-b35c-17f9dd5cf238.png" alt="FM Montano Logo" style={{ height: '40px', marginRight: '10px' }} />
                        FM Montano
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'black' }} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link onClick={() => navigate('/regolamento')} style={{ color: 'black' }}>
                                <FaBook /> Regolamento
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/gestione-attivita')} style={{ color: 'black' }}>
                                <FaEdit /> Gestione Attività
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/mappa')} style={{ color: 'black' }}>
                            <FaMapMarkedAlt /> Mappa Referenze
                             </Nav.Link>

                            <NavDropdown title={<span><FaTrophy /> Classifiche Storiche</span>} id="basic-nav-dropdown" style={{ color: 'black' }}>
                                <NavDropdown.Item onClick={() => navigate('/classifica-qrb')}>Classifica Storica QRB Max</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/classifica-punti-storica')}>Classifica Storica punti</NavDropdown.Item>
                            </NavDropdown>

                            <NavDropdown title={<span><FaTrophy /> Classifiche</span>} id="basic-nav-dropdown" style={{ color: 'black' }}>
                                <NavDropdown.Item onClick={() => navigate('/classifica-attivita')}>Classifica Attivatori 2024</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/classifica-cacciatori-2024')}>Classifica Cacciatori 2024</NavDropdown.Item>
                            </NavDropdown>

                            <Nav.Link onClick={() => navigate('/direttivo')} style={{ color: 'black' }}>
                <FaUserTie /> Direttivo
              </Nav.Link>
                            <Nav.Link onClick={() => navigate('/home')} style={{ color: 'black' }}>
                                <FaHome /> Home
                            </Nav.Link>

                            <Nav.Link onClick={handleLogout} style={{ color: 'black', marginLeft: '20px' }}>
                                <FaSignOutAlt /> Logout
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{
                    minHeight: "95vh",
                    backgroundImage: 'url("/login.webp")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100vw',
                    paddingTop: '75px' // Aggiungi un padding top per compensare l'altezza della navbar fissa
                }}
            >
                <Container>
                    {showAlertNoFoto && (
                        <Alert variant="warning" onClose={() => setShowAlertNoFoto(false)} dismissible>
                            Non ci sono foto disponibili per questa attività.
                        </Alert>
                    )}
                    <h2 style={{ color: 'red', textShadow: '2px 2px 4px #ffffff' }}>Elenco Attività 2024</h2>


                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Control
                                type="text"
                                placeholder="Filtra per Referenza"
                                value={filtroReferenza}
                                onChange={e => setFiltroReferenza(e.target.value)}
                            />
                        </Col>
                        <Col md={6}>
                            <Form.Control
                                type="text"
                                placeholder="Filtra per Nome"
                                value={filtroNomeFmm}
                                onChange={e => setFiltroNomeFmm(e.target.value)}
                            />
                        </Col>
                    </Row>
                    {/* Seconda riga di filtri */}
                    <Row className="mb-3">
                        <Col md={6}>
                            <InputGroup>
                                <Form.Control
                                    type="date"
                                    value={filtroData}
                                    onChange={e => setFiltroData(e.target.value)}
                                />
                                {filtroData && (
                                    <InputGroup.Text onClick={handleResetDataFilter} style={{ cursor: 'pointer' }}>
                                        <FiX />
                                    </InputGroup.Text>
                                )}
                            </InputGroup>
                        </Col>
                        <Col md={6}>
                            <Form.Control
                                type="text"
                                placeholder="Filtra per Nominativo"
                                value={filtroNominativo}
                                onChange={e => setFiltroNominativo(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Attivatore</th>
                                <th>Referenza</th>
                                <th>Punti</th>
                                <th>Data</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {attivitaFiltrate.map((a, index) => (
                                <tr key={index}>
                                 <td style={{ color: (nominativo.trim().toUpperCase() === a.attivatore.trim().toUpperCase() ? 'red' : 'black') }}>
                                        {a.attivatore}
                                    </td>
                                    <td>{a.referenza}</td>
                                    <td>{a.punti.toLocaleString()}</td>
                                    <td>{a.data}</td>
                                    <td>
                                        <FiImage onClick={() => handleVisualizzaFoto(a)} style={{ cursor: 'pointer' }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Dettagli Attività</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {attivitaSelezionata ? (
                                <>
                                    {/* Testo come titolo principale */}
                                    <p style={{ fontSize: '14px', marginBottom: '20px' }}>
                                        {attivitaSelezionata.testo || 'Attività Senza Testo'}
                                    </p>

                                    {/* Dettagli dell'attività */}
                                    <p><strong>Attivatore:</strong> {attivitaSelezionata.attivatore}</p>
                                    <p><strong>Referenza:</strong> {attivitaSelezionata.referenza}</p>
                                    <p><strong>Data:</strong> {moment(attivitaSelezionata.data, "DD-MM-YYYY").format('DD/MM/YYYY')}</p>
                                    <p><strong>Punti:</strong> {attivitaSelezionata.punti}</p>
                                    <p><strong>Locator FMM:</strong> {attivitaSelezionata.locator_fmm}</p>
                                    <p><strong>Nome FMM:</strong> {attivitaSelezionata.nome_fmm}</p>
                                    <p><strong>Punti Bonus:</strong> {attivitaSelezionata.punti_bonus}</p>
                                    <p><strong>Totale QRB:</strong> {attivitaSelezionata.qrbTotale} km</p>

                                    <h4 style={{ color: 'red', textShadow: '2px 2px 4px #ffffff', fontWeight: 'bold' }}>QSO Effettuati:</h4>
                                    {attivitaSelezionata.qso && attivitaSelezionata.qso.length > 0 ? (
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Cacciatore</th>
                                                    <th>Orario</th>
                                                    <th>Frequenza</th>
                                                    <th>QRB (km)</th>
                                                    {/* Aggiungi qui altre intestazioni di colonna se necessario */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {attivitaSelezionata.qso.map((qso, index) => (
                                                    <tr key={index}>
                                                        <td>{qso.cacciatore || 'N/A'}</td>
                                                        <td>{qso.orario || 'N/A'}</td>
                                                        <td>{qso.frequenza || 'N/A'}</td>
                                                        <td>{qso.qrb || 'N/A'}</td>
                                                        {/* Aggiungi qui altre celle se hai più campi da visualizzare */}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <p>Non ci sono QSO disponibili per questa attività.</p>
                                    )}

                                    <div className="grid-container">
                                        {['foto', 'foto2', 'foto3', 'foto4'].reduce((acc, key) => {
                                            if (attivitaSelezionata[key]) {
                                                acc.push(
                                                    <div key={key} className="grid-item">
                                                        <img src={attivitaSelezionata[key]} alt={`Foto Attività ${key}`} className="responsive-img" />
                                                    </div>
                                                );
                                            }
                                            return acc;
                                        }, [])}
                                    </div>

                                </>
                            ) : (
                                <p>Seleziona un'attività per visualizzarne i dettagli.</p>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>Chiudi</Button>
                        </Modal.Footer>
                    </Modal>



                </Container>

            </div>
        </>
    );
}

export default ElencoAttivita;
