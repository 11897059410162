import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FaEdit, FaSignOutAlt, FaHome, FaBook, FaTrophy, FaMapMarkedAlt, FaEnvelope, FaListAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { resetUser } from './store';


// Componente per ogni membro del direttivo
const MembroDirettivo = ({ nome, ruolo, descrizione, foto, email }) => (
    <div className="membro-direttivo" style={{ marginBottom: '2rem' }}>
        <img src={foto} alt={`Foto di ${nome}`} style={{ width: '50%', height: '50%', borderRadius: '40%', objectFit: 'cover' }} />
        <h2>{nome}</h2>
        <p><strong>{ruolo}</strong></p>
        <p>{descrizione}</p>
        {email && (
            <a href={`mailto:${email}`} style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#007bff', marginTop: '0.5rem' }}>
                <FaEnvelope style={{ marginRight: '5px' }} />{email} {/* Aggiunta dell'icona e riduzione del margine */}
            </a>
        )}
    </div>
);

const DirettivoRadioPmr = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        sessionStorage.clear();
        dispatch(resetUser());
        navigate('/loginPmr');
    };

    // Dati dei membri del direttivo
    const membriDirettivo = [
        {
            nome: "IZ1FUM OP. DAVIDE",
            ruolo: "MANAGER NAZIONALE FM MONTANO – RELAZIONI ESTERNE – LOGISTICA –RESPONSABILE  ORGANIZZAZIONE  SPEDIZIONI RADIO Nazionale FM Montano",
            descrizione: "Classe 1977, è appassionato di radiotrasmissioni dal 1990 e radioamatore dal 2002 . Nel corso degli anni è stato operatore cw e fonia nel corso di attivazioni, manifestazioni  ed esibizioni pubbliche. E’ socio Ari della Sezione di Genova  e operatore radio di protezione civile. Abilitato al soccorso sanitario avanzato Blsd e Pblsd, dal 1998 è Autista Soccorritore di Ambulanza e Automedica in forza al 118 Genova Soccorso. In passato ha lavorato come Pisteur/Soccorritore nel comprensorio del Monterosa Ski  in Valle D’Aosta. E’ stato attivato in occasione di alcune Maxi emergenze in Liguria tra cui l’alluvione di Genova del  2011 ed il crollo del Ponte Morandi nel 2018. Fondatore nel 2015 del Diploma FmMontano, pratica sci, sci alpinismo e snowboard. Da adolescente è stato agonista di sci e di tennis e ha praticato guida su Kart ed auto da rally frequentando il Corso di guida sportiva presso la scuderia Busalla 90. Nel  2005 ha superato brillantemente le selezioni come stunt driver per la produzione cinematografica 007 Casinò Royale.  Attivo tutti i week end dai monti dell’Appennino Ligure, è da sempre accanito sostenitore della potenzialità a lungo raggio degli apparati palmari vhf/uhf. Libertà di fare radio in quota ed in movimento. FmMontano per lui è questo.",
            foto: "/davide.jpg", // Sostituisci con il percorso corretto
            email: "iz1fum @gmail.com"
        },
        {
            nome: "IU1LBK OP. GIACOMO",
            ruolo: "PROJECT MANAGER PER LO SVILUPPO WEB WWW.FMMONTANO.COM – MANAGER REGIONE LIGURIA",
            descrizione: "Giacomo è il project manager responsabile per lo sviluppo del sito web FMMontano e ricopre anche il ruolo di manager per la regione Liguria. Con una grande passione per la radio e l'operatività in montagna, ha consolidato la sua esperienza vincendo il premio FM Montano per due anni consecutivi. Il suo impegno e dedizione lo hanno portato a diventare una figura chiave all'interno della comunità FM Montano, sostenendo la libertà di esplorare e comunicare attraverso la radio in quota ed in movimento.",
            foto: "/giacomo.jpg", // Sostituisci con il percorso corretto
            email: "giacomo.frola@gmail.com" // Aggiungi l'email corretta
        },
        {
            nome: "IU1MQU OP. PAOLO",
            ruolo: "MANAGER REGIONE LIGURIA",
            descrizione: "Nasce a Rapallo in provincia di Genova nel 1973 sviluppando sin da piccolo un forte interesse per la ltecnologia e per le comunicazioni radio. Primi passi sulla banda cittadina CB dei 27 MHZ. In seguito si dedica al radioascolto su tutte la bande e consegue la patente da radioamatore spinto dalla voglia di sperimentare e  raggiungere nuovi obiettivi. E’ spesso operativo in mobile sia in simplex che via ripetitore ed in attività escursionistica, a 145.500 per il programma FmMontano. In parallelo è attivo in modalità DMR dove ha raggiunto ottime conoscenze tecniche operative e di programmazione. Ha praticato per diversi anni ciclismo a livello agonistico.",
            foto: "/paolo.jpg", // Sostituisci con il percorso corretto
            email: "paolobernardi73@gmail.com"
        },
        {
            nome: "IZ1UMJ OP. MARCO",
            ruolo: "MANAGER REGIONE PIEMONTE",
            descrizione: "Classe 79’, vive in Piemonte in provincia di Novara dove lavora come Tecnico di reti Gas. Giovanissimo si appassiona alla radio militando sugli 11 metri e dando vita ad un vero e proprio movimento giovanile CB nel nord del Piemonte. Consegue la patente nel 2011, pratica per diversi anni Mountain Bike a livello agonistico nella disciplina cross country Xc partecipando a diverse gare nella zona di Bardonecchia provincia di Torino e raggiungendo i 3000 mt del Colle Sommeiller. Successivamente inizia a fare trekking individuando nuovi target per essere operativo in radio. Nel 2017, per puro caso, intercetta la chiamata in 2mt. Fm di Davide IZ1FUM in viaggio da Genova in direzione della Valle d'Aosta (IX1), un incontro non programmato che segna l’inizio di una grande amicizia e che lo avvicina con entusiasmo al Progetto FmMontano.",
            foto: "/marco.jpg", // Sostituisci con il percorso corretto
            email: "gimatek@hotmail.com"
        },
        {
            nome: "IZ1HVD OP. DANILO",
            ruolo: "MANAGER REGIONE PIEMONTE- MANAGER NAZIONALE CB MONTANO",
            descrizione: "Classe 74', vive in Piemonte nelle Valli di Lanzo in provincia di Torino e lavora nel settore educativo e scolastico. Affascinato e interessato alle radiocomunicazioni fin da piccolo grazie al papà e alla mamma (entrambi entusiasti CB ed SWL che, insieme allo zio tecnico RAI, gli hanno trasmesso questa grande e stupenda passione...conserva gelosamente il CB GBC Tenko 23+ e due Ricevitori Telefunken HF e VHF valvolari di famiglia) negli anni ha coltivato instancabilmente il desiderio di approfondire le proprie conoscenze in ambito radiantistico e nella sperimentazione. Oggi è un attivo e impegnato CB, PMR446, SWL, BCL, Radioamatore con nominativo Italiano, Svizzero, Irlandese e Americano. Devoto alla semplicità e alle radiocomunicazioni a debole potenza, ha eletto il QRP e il minimal quale personale modo di vivere e di esistere. Predilige le attività in Portatile in Outdoor e ambiente montano, VHF & Up in FM, è Promoter Italiano del GMA - Global Mountain Activity, Referente Regionale AlpiRadio, Referente Locale di Rete Radio Montana, Fondatore e Referente di CB MONTANO. È impegnato nella Protezione Civile, Operatore Radio nel COM e in SOSmontagna O.D.V.P.C. Radiocomunicazioni per la Sicurezza in Montagna. Pratica Hiking, Trekking, MTB e navigazione/discesa fluviale con canoa e kayak. È un Operatore Sportivo in Ambito Equestre, Exotic Sitter Qualificato, Fotografo Naturalista, appassionato di Retrocomputing e Retrogaming.",
            foto: "/danilo.jpg", // Sostituisci con il percorso corretto
            email: "iz1hvd@gmail.com"
        }

        // ... altri membri
    ];

    return (
        <>
            <Navbar fixed="top" expand="false" style={{
                borderBottom: '1px solid #dee2e6',
                backgroundColor: 'rgba(255, 255, 255, 0.7)', // Uguale al contenitore del messaggio
                backdropFilter: 'blur(5px)', // Opzionale: mantiene l'effetto sfocatura
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Consistente con lo stile del contenitore del messaggio
            }}>
                <Container>
                    <Navbar.Brand href="#" style={{ color: 'black', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                    <img src="/pmr.png" alt="Pmr Logo" style={{ height: '40px', marginRight: '10px' }} />
                        Pmr Montano
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: 'black' }} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link onClick={() => navigate('/regolamentoPmr')} style={{ color: 'black' }}>
                                <FaBook /> Regolamento
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/gestione-attivitaPmr')} style={{ color: 'black' }}>
                                <FaEdit /> Gestione Attività
                            </Nav.Link>
                            <Nav.Link onClick={() => navigate('/elenco-attivitaPmr')} style={{ color: 'black' }}>
                                <FaListAlt /> Elenco Attività
                            </Nav.Link>

                            {/* Dropdown per le classifiche */}
                            <NavDropdown title={<span><FaTrophy /> Classifiche</span>} id="basic-nav-dropdown" style={{ color: 'black' }}>
                                <NavDropdown.Item onClick={() => navigate('/classifica-attivitaPmr')}>Classifica Attivatori 2024</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => navigate('/classifica-cacciatori-2024Pmr')}>Classifica Cacciatori 2024</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link onClick={() => navigate('/homePmr')} style={{ color: 'black' }}>
                                <FaHome /> Home
                            </Nav.Link>

                            <Nav.Link onClick={handleLogout} style={{ color: 'black', marginLeft: '20px' }}>
                                <FaSignOutAlt /> Logout
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div style={{ paddingTop: '70px' }}>
                {membriDirettivo.map((membro, index) => (
                    <MembroDirettivo key={index} {...membro} />
                ))}
            </div>
        </>
    );
};


export default DirettivoRadioPmr;
