import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Modal, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import './Fmattivita.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { resizeImage } from './resizeImage';


function locatorToLatLng(locator) {
    const lats = "ABCDEFGHIJKLMNOPQRSTUVWX";
    const lngs = "ABCDEFGHIJKLMNOPQR";
    let lat = lats.indexOf(locator.charAt(1).toUpperCase()) * 10 - 90 + parseInt(locator.charAt(3)) - ('0') + (parseInt(locator.charAt(5).toUpperCase().charCodeAt(0)) - 'A'.charCodeAt(0)) / 24 + 1 / 48;
    let lng = lngs.indexOf(locator.charAt(0).toUpperCase()) * 20 - 180 + parseInt(locator.charAt(2)) * 2 + (parseInt(locator.charAt(4).toUpperCase().charCodeAt(0)) - 'A'.charCodeAt(0)) / 12 + 1 / 24;
    return { lat, lng };
}

function calculateDistance(lat1, lng1, lat2, lng2) {
    const R = 6371; // Raggio della Terra in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLng = (lng2 - lng1) * Math.PI / 180;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distanza in km
    return distance;
}

function FmAttivita() {
    const location = useLocation();
    const { state } = location;
    const [qsoList, setQsoList] = useState([]);
    const navigate = useNavigate(); // Hook per il reindirizzamento
    const [showSuccess, setShowSuccess] = useState(false); // Stato per il messaggio di successo
    const [showError, setShowError] = useState(false); // Stato per il messaggio di errore

    const [cacciatore, setCacciatore] = useState('');
    const [locatore, setLocatore] = useState('');
    const [frequenza, setFrequenza] = useState('2m');
    const [modo, setModo] = useState('FM');
    const [orario, setOrario] = useState(moment().format('HH:mm'));
    const [editErrors, setEditErrors] = useState({});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [ref_fmm, setRefFmm] = useState('');
    const [nomeFmm, setNomeFmm] = useState('');
    const [punti, setPunti] = useState(0);
    const [puntiBonus, setPuntiBonus] = useState(0);
    const [locatorFmm, setLocatorFmm] = useState('');
    const [data, setDataAttivita] = useState('');
    const [nominativo, setNominativo] = useState('');
    const attivita = location.state?.attivita;
    const [messaggioInviato, setMessaggioInviato] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [testoAttivita, setTestoAttivita] = useState("");
    const [fotoAttivita, setFotoAttivita] = useState(null);
    const [fotoAttivita2, setFotoAttivita2] = useState(null);
    const [fotoAttivita3, setFotoAttivita3] = useState(null);
    const [fotoAttivita4, setFotoAttivita4] = useState(null);
    const [nuovaRef, setNuovaRef] = useState(false);

    const [showConfirmSendModal, setShowConfirmSendModal] = useState(false);


    const [tempQso, setTempQso] = useState(null);

    const handleClose = () => setShowConfirmModal(false);
    const handleShow = () => setShowConfirmModal(true);

    const handleConfirmLeave = () => {
        navigate('/home'); // Naviga alla home se l'utente conferma
    };

    const [errors, setErrors] = useState({
        cacciatore: '',
        locatore: '',
        frequenza: '',
        orario: ''
    });
    const uuid = sessionStorage.getItem('userUUID');
    const [editingIndex, setEditingIndex] = useState(null); // Inizialmente nessun QSO è in modifica

    const handleConfirmSend = () => {
        inviaMessaggioTelegram("ATTIVAZIONE FM MONTANO DI  " + state.nominativo + "  Da " + state.ref_fmm + "  " + state.nomeFmm);
        setShowConfirmSendModal(false);
    };
    
    // Gestisci l'annullamento del modale
    const handleCancelSend = () => {
        setShowConfirmSendModal(false);
    };


    useEffect(() => {

        let bonusTemporaneo = 0;
        setModo('FM');
        setFrequenza('2m')
        setOrario(moment().format('HH:mm'));
        if (attivita) {
            setRefFmm(attivita.referenza);
            setNomeFmm(attivita.nome_fmm); // Assumi che nomeFmm sia la chiave corretta in attivita
            setLocatorFmm(attivita.locator_fmm); // Assumi che locatorFmm sia la chiave corretta in attivita
            setDataAttivita(attivita.data);
            setNominativo(attivita.attivatore);
            setPunti(attivita.punti)
            setPuntiBonus(attivita.punti_bonus)
            setTestoAttivita(attivita.testo)

            const fetchQSO = async () => {
                const basicAuth = 'Basic ' + btoa("FmMontano:FmMontano24");
                // Assumo che tu abbia l'UUID e l'ID dell'attività disponibili qui
                const requestBody = {
                    uuid: attivita.uuid, // Verifica che questa sia la chiave corretta per l'UUID nell'oggetto attivita
                    idAttivita: attivita.id // Verifica che questa sia la chiave corretta per l'ID nell'oggetto attivita
                };

                try {
                    const response = await fetch('/fmMontano/trova-qso', { // Assicurati che l'URL sia corretto
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': basicAuth,
                        },
                        body: JSON.stringify(requestBody),
                    });

                    if (response.ok) {
                        const qsoData = await response.json();
                        setQsoList(qsoData);
                    } else {
                        throw new Error('Errore nel recupero dei QSO');
                    }
                } catch (error) {
                    console.error("Errore nella chiamata fetchQSO:", error);
                }
            };

            fetchQSO();

        } else {
            setShowConfirmSendModal(true); // Mostra il modale per confermare l'invio
            setRefFmm(state.ref_fmm);
            setNomeFmm(state.nomeFmm); // Assumi che nomeFmm sia la chiave corretta in attivita
            setLocatorFmm(state.locatorFmm); // Assumi che locatorFmm sia la chiave corretta in attivita
            setDataAttivita(state.data); // Formatta la data come necessario
            setNominativo(state.nominativo);

            if (!state.omFmm || state.omFmm.trim() === '') {
                bonusTemporaneo += 250;
                setNuovaRef(true)
            }

            if (state.isAutocostruzione) {
                bonusTemporaneo += 200;
            }

            if (state.isDueOre) {
                bonusTemporaneo += 200;
            }

            setPuntiBonus(puntiBonus + bonusTemporaneo);
            setPunti(punti + bonusTemporaneo);

        }


    }, [attivita, state]);

    function inviaMessaggioTelegram(messaggio) {
        if (messaggioInviato) {
            return;
        }

        const url = `/fmMontano/inviaMessaggioTelegram?messaggio=${encodeURIComponent(messaggio)}`;

        fetch(url, {
            method: 'GET', // Metodo HTTP definito nel controller
        })
            .then(response => {
                if (response.ok) {
                    setMessaggioInviato(true); // Imposta che il messaggio è stato inviato
                    return response.text(); // Gestisci la risposta come testo
                }
                throw new Error('Errore nell\'invio del messaggio');
            })
            .then() // Qui data sarà la stringa di testo della risposta
            .catch(error => console.error('Errore:', error));
    }


    const aggiornaPuntiTotali = () => {
        const nuoviPuntiDaQSO = qsoList.reduce((acc, qso) => {
            // Controlla se la frequenza del QSO è "70cm" e moltiplica i punti per 1,3 se lo è
            const moltiplicatore = (qso.frequenza === "70cm" || qso.frequenza === "23cm") ? 1.3 : 1;
            return acc + (qso.qrb * moltiplicatore);
        }, 0);
        // Arrotonda a non più di una cifra dopo la virgola e converti in numero
        const puntiTotali = parseFloat((nuoviPuntiDaQSO + puntiBonus).toFixed(1));
        setPunti(puntiTotali); // Includi i punti bonus nel calcolo del totale
    };


    const validateForm = () => {
        let newErrors = {};
        let formIsValid = true;

        if (!cacciatore) {
            newErrors.cacciatore = "Il campo Cacciatore è obbligatorio.";
            formIsValid = false;
        } else if (cacciatore.length > 10) {
            newErrors.cacciatore = "Il nominativo non può superare i 10 caratteri.";
            formIsValid = false;
        }

        if (!locatore) {
            newErrors.locatore = "Il campo Locatore è obbligatorio.";
            formIsValid = false;
        } else if (!isValidLocator(locatore)) {
            newErrors.locatore = "Il formato del Locatore non è valido. Deve essere nel formato AA00AA.";
            formIsValid = false;
        }

        if (!frequenza) {
            newErrors.frequenza = "Il campo Banda è obbligatorio.";
            formIsValid = false;
        }

        if (!modo) {
            newErrors.modo = "Il campo Modo è obbligatorio.";
            formIsValid = false;
        }

        if (!orario) {
            newErrors.orario = "Il campo Orario è obbligatorio.";
            formIsValid = false;
        }

        setErrors(newErrors);
        return formIsValid;
    };

    const handleSave = async () => {
        let requestBody;

        if (attivita) {
            requestBody = {
                uuid: uuid,
                attivatore: nominativo,
                referenza: ref_fmm,
                qrbTotale: totalQrb,
                data: saveData,
                nome_fmm: nomeFmm,
                locator_fmm: locatorFmm,
                id: attivita.id,
                punti: punti,
                punti_bonus: puntiBonus,
                testo: testoAttivita,
                flagNuovo: nuovaRef
                // Non impostare qui la foto, la aggiungeremo dopo
            }
        } else {
            requestBody = {
                uuid: uuid,
                attivatore: nominativo,
                referenza: ref_fmm,
                qrbTotale: totalQrb,
                data: saveData,
                nome_fmm: nomeFmm,
                locator_fmm: locatorFmm,
                punti: punti,
                punti_bonus: puntiBonus,
                testo: testoAttivita,
                flagNuovo: nuovaRef
                // Non impostare qui la foto, la aggiungeremo dopo
            }
        };

        // Converte e ridimensiona la foto in Base64 prima di procedere con il salvataggio
        if (fotoAttivita) {
            const fotoRidimensionata = await resizeImage({
                file: fotoAttivita,
                maxSize: 500 // Imposta la dimensione massima dell'immagine in pixel
            });

            // Aggiungi la foto ridimensionata all'oggetto requestBody
            requestBody.foto = fotoRidimensionata;
        }
        if (fotoAttivita2) {
            const fotoRidimensionata2 = await resizeImage({
                file: fotoAttivita2,
                maxSize: 500 // Imposta la dimensione massima dell'immagine in pixel
            });

            // Aggiungi la foto ridimensionata all'oggetto requestBody
            requestBody.foto2 = fotoRidimensionata2;
        }
        if (fotoAttivita3) {
            const fotoRidimensionata3 = await resizeImage({
                file: fotoAttivita3,
                maxSize: 500 // Imposta la dimensione massima dell'immagine in pixel
            });

            // Aggiungi la foto ridimensionata all'oggetto requestBody
            requestBody.foto3 = fotoRidimensionata3;
        }
        if (fotoAttivita4) {
            const fotoRidimensionata4 = await resizeImage({
                file: fotoAttivita4,
                maxSize: 500 // Imposta la dimensione massima dell'immagine in pixel
            });

            // Aggiungi la foto ridimensionata all'oggetto requestBody
            requestBody.foto4 = fotoRidimensionata4;
        }

        // Procedi con l'invio dei dati
        sendFormData(requestBody);
    };

    // Funzione per inviare i dati al server
    const sendFormData = async (attivitaDetails) => {
        const qsoListWithDetails = qsoList.map(qso => ({
            ...qso,
            uuid: uuid,
            attivatore: nominativo,
            data: saveData,
            referenza: ref_fmm
        }));
        try {
            const response = await fetch('/fmMontano/salva_attivita_e_qso', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    attivita: attivitaDetails, // Invia l'oggetto attività con tutti i dettagli
                    qso: qsoListWithDetails, // E i QSO come parte del payload JSON
                }),
            });

            if (!response.ok) {
                throw new Error('Errore nel salvataggio dei dati');
            }

            setShowModal(false);
            setShowSuccess(true);

            // Opzionalmente, naviga dopo un breve ritardo
            setTimeout(() => {
                navigate('/home');
            }, 2000);
        } catch (error) {
            console.error(error);
            setShowModal(false);
            setShowError(true);
        }
    };









    const handleAddQso = () => {
        if (!validateForm()) {
            // Se la validazione fallisce, non procedere con l'aggiunta del QSO
            return;
        }

        // Procedi con l'aggiunta del QSO se il form è valido
        const locatorFmmLatLng = locatorToLatLng(locatorFmm);
        const locatoreLatLng = locatorToLatLng(locatore);
        const qrb = Math.round(calculateDistance(locatorFmmLatLng.lat, locatorFmmLatLng.lng, locatoreLatLng.lat, locatoreLatLng.lng));

        const cleanedCacciatore = cacciatore.trim().toUpperCase();
        setQsoList(prevQsoList => {
            // Aggiunge il nuovo QSO all'elenco esistente
            const updatedQsoList = [...prevQsoList, {
                cacciatore: cleanedCacciatore,
                locatore: locatore.toUpperCase(),
                frequenza: frequenza,
                orario,
                qrb,
                modo
            }];

            // Calcola i nuovi punti da QSO, moltiplicando per 1,3 se la frequenza è "70cm"
            const nuoviPuntiDaQSO = updatedQsoList.reduce((acc, qso) => {
                const moltiplicatore = (qso.frequenza === "70cm" || qso.frequenza === "23cm") ? 1.3 : 1;
                return acc + (qso.qrb * moltiplicatore);
            }, 0);

            const puntiTotali = parseFloat((nuoviPuntiDaQSO + puntiBonus).toFixed(1));
            setPunti(puntiTotali); // Includi i punti bonus nel calcolo del totale

            // Ritorna l'elenco QSO aggiornato
            return updatedQsoList;
        });

        // Pulisci i campi del form dopo l'aggiunta
        setCacciatore('');
        setLocatore('');
        setFrequenza('2m');
        setModo('FM');
        setOrario(moment().format('HH:mm'));
    };


    const totalQrb = qsoList.reduce((acc, qso) => acc + qso.qrb, 0);

    const handleCancelEditModal = () => {
        if (editingIndex !== null && tempQso) {
            // Ripristina il QSO originale nella lista, scartando le modifiche non salvate
            const updatedQsoList = [...qsoList];
            updatedQsoList[editingIndex] = tempQso;
            setQsoList(updatedQsoList);
        }

        // Resetta l'indice di modifica e il QSO temporaneo
        setEditingIndex(null);
        setTempQso(null);
        setEditErrors({});
    };


    const isValidLocator = (locator) => {
        return /^[A-R]{2}[0-9]{2}[A-X]{2}$/i.test(locator);
    };

    function updateEditingQso(field, value) {
        const updatedQsoList = [...qsoList];
        const updatedQso = { ...updatedQsoList[editingIndex], [field]: value };

        // Se si modifica il locatore, ricalcola il QRB
        if (field === 'locatore' && isValidLocator(value)) {
            const locatorFmmLatLng = locatorToLatLng(locatorFmm); // Locator dell'attivatore
            const locatoreLatLng = locatorToLatLng(value); // Nuovo locatore del cacciatore
            const qrb = Math.round(calculateDistance(locatorFmmLatLng.lat, locatorFmmLatLng.lng, locatoreLatLng.lat, locatoreLatLng.lng));
            updatedQso.qrb = qrb;
        }

        updatedQsoList[editingIndex] = updatedQso;
        setQsoList(updatedQsoList);
    }

    const handleEditQso = (index) => {
        setEditingIndex(index);
        setTempQso({ ...qsoList[index] });
    };

    function handleSaveEditedQso() {
        const updatedQso = qsoList[editingIndex];
        let isValid = true;
        let newEditErrors = {};

        // Validazione Cacciatore
        updatedQso.cacciatore = updatedQso.cacciatore.trim();
        if (!updatedQso.cacciatore) {
            newEditErrors.cacciatore = "Il campo Cacciatore è obbligatorio.";
            isValid = false;
        } else if (updatedQso.cacciatore.length > 10) {
            newEditErrors.cacciatore = "Il campo Cacciatore non può superare i 10 caratteri.";
            isValid = false;
        }

        // Validazione Locatore
        if (!updatedQso.locatore.trim() || !isValidLocator(updatedQso.locatore)) {
            newEditErrors.locatore = "Locatore non valido. Deve essere nel formato AA00AA.";
            isValid = false;
        }

        // Validazione Frequenza con la logica specifica richiesta
        if (!updatedQso.frequenza.trim()) {
            newEditErrors.frequenza = "Il campo Banda è obbligatorio.";
            isValid = false;
        }

        if (!updatedQso.modo.trim()) {
            newEditErrors.modo = "Il campo Modo è obbligatorio.";
            isValid = false;
        }

        // Validazione Orario
        if (!updatedQso.orario.trim()) {
            newEditErrors.orario = "Il campo Orario è obbligatorio.";
            isValid = false;
        }

        if (!isValid) {
            // Imposta errori di validazione e impedisce il salvataggio
            setEditErrors(newEditErrors);
            return;
        }

        // Se la validazione è superata, prosegui con il salvataggio
        setEditErrors({}); // Pulisci gli errori
        setEditingIndex(null); // Chiudi la modale

        setTempQso(null);
        aggiornaPuntiTotali();
    }

    const handleDeleteQso = (index) => {
        setQsoList(prevQsoList => {
            // Crea una copia dell'elenco QSO e rimuove l'elemento specificato
            const updatedQsoList = [...prevQsoList];
            updatedQsoList.splice(index, 1); // Rimuove l'elemento dalla lista

            // Calcola i nuovi punti da QSO, moltiplicando per 1,3 se la frequenza è "70cm"
            const nuoviPuntiDaQSO = updatedQsoList.reduce((acc, qso) => {
                const moltiplicatore = (qso.frequenza === "70cm" || qso.frequenza === "23cm") ? 1.3 : 1;
                return acc + (qso.qrb * moltiplicatore);
            }, 0);

            const puntiTotali = parseFloat((nuoviPuntiDaQSO + puntiBonus).toFixed(1));
            setPunti(puntiTotali); // Includi i punti bonus nel calcolo del totale

            // Ritorna la nuova lista dei QSO aggiornata
            return updatedQsoList;
        });
    };


    if (!state) {
        return <div>Nessun dato disponibile</div>;
    }


    const formattedDate = moment(data).format('DD/MM/YYYY');
    const saveData = moment(data).format('DD-MM-YYYY')

    return (
        <Container>
            {showSuccess && (
                <div className="alert alert-success" role="alert">
                    Attività e QSO salvati con successo!
                </div>
            )}
            {showError && (
                <div className="alert alert-danger" role="alert">
                    Il Salvataggio non andato a buon fine.
                </div>
            )}

            <div className="container-top">
                <h1>Dati Attività</h1>
                <Row>
                    <Col>
                        <strong> Referenza FMM:</strong><span className="referenza-fmm">{ref_fmm}</span>
                    </Col>
                    <Col>
                        <strong>Nome FMM:</strong> {nomeFmm}
                    </Col>
                    <Col>
                        <strong>Locator FMM:</strong> {locatorFmm}
                    </Col>
                    <Col>
                        <strong>Data:</strong> {formattedDate}
                    </Col>
                    <Col>
                        <strong>Nominativo:</strong> {nominativo}
                    </Col>
                </Row>
            </div>

            <div className="form-container mt-3">
                <h2>Inserisci Nuovo QSO</h2>
                <Row className="mb-3">
                    <Col xs={12} md={6}>
                        <label htmlFor="inputCacciatore" className="form-label">Cacciatore</label>
                        <input
                            type="text"
                            className={`form-control ${errors.cacciatore ? 'is-invalid' : ''}`}
                            id="inputCacciatore"
                            placeholder="Nominativo"
                            value={cacciatore}
                            onChange={(e) => {
                                setCacciatore(e.target.value.toUpperCase());
                                if (errors.cacciatore) {
                                    setErrors({ ...errors, cacciatore: '' });
                                }
                            }}
                        />
                        {errors.cacciatore && <div className="invalid-feedback">{errors.cacciatore}</div>}
                    </Col>

                    <Col xs={12} md={6}>
                        <label htmlFor="inputLocatore" className="form-label">Locatore</label>
                        <input
                            type="text"
                            className={`form-control ${errors.locatore ? 'is-invalid' : ''}`}
                            id="inputLocatore"
                            placeholder="Locator"
                            value={locatore}
                            onChange={(e) => {
                                setLocatore(e.target.value.toUpperCase());
                                if (errors.locatore) {
                                    setErrors({ ...errors, locatore: '' });
                                }
                            }}
                        />
                        {errors.locatore && <div className="invalid-feedback">{errors.locatore}</div>}
                    </Col>

                    <Col xs={12} md={6}>
                        <label htmlFor="selectFrequenza" className="form-label">Banda</label>
                        <select
                            className={`form-control ${errors.frequenza ? 'is-invalid' : ''}`}
                            id="selectFrequenza"
                            value={frequenza}
                            onChange={(e) => {
                                setFrequenza(e.target.value);
                                if (errors.frequenza) {
                                    setErrors({ ...errors, frequenza: '' });
                                }
                            }}
                        >
                            <option value="">Seleziona la banda</option>
                            <option value="10m">10m</option>
                            <option value="6m">6m</option>
                            <option value="2m">2m</option>
                            <option value="70cm">70cm</option>
                            <option value="23cm">23 cm</option>
                        </select>
                        {errors.frequenza && <div className="invalid-feedback">{errors.frequenza}</div>}
                    </Col>

                    <Col xs={12} md={6}>
                        <label htmlFor="selectModo" className="form-label">Modo</label>
                        <select
                            className={`form-control ${errors.modo ? 'is-invalid' : ''}`}
                            id="selectModo"
                            value={modo}
                            onChange={(e) => {
                                setModo(e.target.value);
                                if (errors.modo) {
                                    setErrors({ ...errors, modo: '' });
                                }
                            }}
                        >
                            <option value="">Seleziona il modo</option>
                            <option value="FM">FM</option>
                            <option value="C4FM">C4FM</option>
                            <option value="DMR">DMR</option>
                            <option value="DSTAR">DSTAR</option>
                        </select>
                        {errors.modo && <div className="invalid-feedback">{errors.modo}</div>}
                    </Col>



                    <Col xs={12}>
                        <label htmlFor="inputOrario" className="form-label">Orario</label>
                        <input
                            type="time"
                            className={`form-control ${errors.orario ? 'is-invalid' : ''}`}
                            id="inputOrario"
                            value={orario}
                            onChange={(e) => {
                                setOrario(e.target.value);
                                if (errors.orario) {
                                    setErrors({ ...errors, orario: '' });
                                }
                            }}
                        />
                        {errors.orario && <div className="invalid-feedback">{errors.orario}</div>}
                    </Col>

                </Row>
                <Row className="mb-3">
                    <Col>
                        <Button variant="primary" className="w-100" onClick={handleAddQso}>Aggiungi QSO</Button>
                    </Col>
                </Row>
            </div>

            <Row className="mt-3"> {/* Aggiunge un margine superiore per distanziare dalla sezione precedente */}
                <Col xs={12}>
                    <h3>Log Attività</h3>
                    <div className="table-responsive">
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Cacciatore</th>
                                    <th>Locatore</th>
                                    <th>QRB (km)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {qsoList.map((qso, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{qso.cacciatore}</td>
                                        <td>{qso.locatore}</td>
                                        <td>{qso.qrb}</td>
                                        <td>
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                onClick={() => handleEditQso(index)}
                                                style={{ marginRight: 10, cursor: 'pointer' }}
                                            />
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                onClick={() => handleDeleteQso(index)}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
            <Row className="mt-3 text-center">
                <Col>
                    <h5>QSO: {qsoList.length}</h5>
                </Col>
                <Col>
                    <h5>QRB: {totalQrb} km</h5>
                </Col>
                <Col>
                    <h5>Punti: <span style={{ color: 'red', fontWeight: 'bold' }}>{punti}</span></h5>
                </Col>

            </Row>
            <Row className="justify-content-between align-items-center my-3">
                <Col xs="auto">
                    <Button variant="secondary" onClick={handleShow}>
                        <FontAwesomeIcon icon={faArrowCircleLeft} /> Torna alla Home
                    </Button>
                </Col>
                <Col xs="auto">
                    <Button variant="success" onClick={() => setShowModal(true)}>Salva Attività</Button>
                </Col>
            </Row>
            <Modal show={showConfirmModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Conferma uscita</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sei sicuro di voler uscire? Le modifiche non salvate andranno perse.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Annulla
                    </Button>
                    <Button variant="primary" onClick={handleConfirmLeave}>
                        Conferma
                    </Button>
                </Modal.Footer>
            </Modal>
            {editingIndex !== null && (
                <Modal show={editingIndex !== null} onHide={handleCancelEditModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modifica QSO</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {/* Cacciatore */}
                            <Form.Group className="mb-3" controlId="editCacciatore">
                                <Form.Label>Cacciatore</Form.Label>
                                <Form.Control
                                    type="text"
                                    isInvalid={!!editErrors.cacciatore}
                                    value={qsoList[editingIndex]?.cacciatore || ''}
                                    onChange={(e) => updateEditingQso('cacciatore', e.target.value.toUpperCase())}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {editErrors.cacciatore}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* Locatore */}
                            <Form.Group className="mb-3" controlId="editLocatore">
                                <Form.Label>Locatore</Form.Label>
                                <Form.Control
                                    type="text"
                                    isInvalid={!!editErrors.locatore}
                                    value={qsoList[editingIndex]?.locatore || ''}
                                    onChange={(e) => updateEditingQso('locatore', e.target.value.toUpperCase())}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {editErrors.locatore}
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* Frequenza */}
                            <Form.Group className="mb-3" controlId="editFrequenza">
                                <Form.Label>Banda</Form.Label>
                                <Form.Control
                                    as="select"
                                    isInvalid={!!editErrors.frequenza}
                                    value={qsoList[editingIndex]?.frequenza || ''}
                                    onChange={(e) => updateEditingQso('frequenza', e.target.value)}
                                >
                                    <option value="">Seleziona la banda</option>
                                    <option value="10m">10m</option>
                                    <option value="6m">6m</option>
                                    <option value="2m">2m</option>
                                    <option value="70cm">70cm</option>
                                    <option value="23cm">23cm</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {editErrors.frequenza}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="editModo">
                                <Form.Label>Modo</Form.Label>
                                <Form.Control
                                    as="select"
                                    isInvalid={!!editErrors.modo}
                                    value={qsoList[editingIndex]?.modo || ''}
                                    onChange={(e) => updateEditingQso('modo', e.target.value)}
                                >
                                    <option value="">Seleziona il modo</option>
                                    <option value="FM">FM</option>
                                    <option value="C4FM">C4FM</option>
                                    <option value="DMR">DMR</option>
                                    <option value="DSTAR">DSTAR</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {editErrors.modo}
                                </Form.Control.Feedback>
                            </Form.Group>


                            {/* Orario */}
                            <Form.Group className="mb-3" controlId="editOrario">
                                <Form.Label>Orario</Form.Label>
                                <Form.Control
                                    type="time"
                                    isInvalid={!!editErrors.orario}
                                    value={qsoList[editingIndex]?.orario || ''}
                                    onChange={(e) => updateEditingQso('orario', e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {editErrors.orario}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCancelEditModal}>Annulla</Button>
                        <Button variant="primary" onClick={handleSaveEditedQso}>Salva Modifiche</Button>
                    </Modal.Footer>
                </Modal>

            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Aggiungi Dettagli Attività</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Testo (Massimo 1000 caratteri)</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                maxLength="1000"
                                placeholder="Inserisci testo"
                                value={testoAttivita}
                                onChange={(e) => setTestoAttivita(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                {testoAttivita.length} / 1000
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Foto</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*" // Accetta solo file immagine
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file && !file.type.startsWith('image/')) {
                                        alert('Per favore, seleziona un file immagine.');
                                        e.target.value = ''; // Resetta il valore dell'input se il file non è un'immagine
                                        return;
                                    }
                                    setFotoAttivita(file);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Foto2</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*" // Accetta solo file immagine
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file && !file.type.startsWith('image/')) {
                                        alert('Per favore, seleziona un file immagine.');
                                        e.target.value = ''; // Resetta il valore dell'input se il file non è un'immagine
                                        return;
                                    }
                                    setFotoAttivita2(file);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Foto3</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*" // Accetta solo file immagine
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file && !file.type.startsWith('image/')) {
                                        alert('Per favore, seleziona un file immagine.');
                                        e.target.value = ''; // Resetta il valore dell'input se il file non è un'immagine
                                        return;
                                    }
                                    setFotoAttivita3(file);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Foto4</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*" // Accetta solo file immagine
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file && !file.type.startsWith('image/')) {
                                        alert('Per favore, seleziona un file immagine.');
                                        e.target.value = ''; // Resetta il valore dell'input se il file non è un'immagine
                                        return;
                                    }
                                    setFotoAttivita4(file);
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Chiudi</Button>
                    <Button variant="primary" onClick={handleSave}>Salva Dettagli</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmSendModal} onHide={handleCancelSend}>
                <Modal.Header closeButton>
                    <Modal.Title>Conferma Invio Spot</Modal.Title>
                </Modal.Header>
                <Modal.Body>L'attivazione è in corso? Se premi "Sì" verrà mandato uno spot sui nostri canali.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancelSend}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleConfirmSend}>
                        Sì
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>

    );
}

export default FmAttivita;
