import React, { useState } from 'react';
import { Form, Button, Alert, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function LoginComponent() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setSubmitted(true);



    if (!email || !password) {
      setError('Per favore, inserisci tutti i campi.');
      return;
    }

    try {
      const basicAuth = 'Basic ' + btoa("FmMontano:FmMontano24");

      const response = await fetch('/fmMontano/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': basicAuth,
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const user = await response.json();
        setShowSuccess(true);
        sessionStorage.setItem('userUUID', user.uuid);
        sessionStorage.setItem('nominativo', user.nominativo);
        setTimeout(() => {
          navigate('/generale');
        }, 2000); 
      } else {
        const errorMessage = await response.text();
        setError(errorMessage);
      }
    } catch (error) {
      console.error('Errore di rete:', error);
      setError('Errore di rete durante il tentativo di login.');
    }
  };

  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "95vh",
          backgroundImage: 'url("/login.webp")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
        }}
      >
        <div className="w-100" style={{ maxWidth: "400px", backgroundColor: "rgba(192, 192, 192, 0.7)", padding: "20px", borderRadius: "15px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
          <h2 className="text-center mb-4" style={{ color: "black" }}>FM Montano</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleLogin}>
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                required
                isInvalid={submitted && !email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Per favore inserisci un'email valida.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                required
                isInvalid={submitted && !password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Per favore inserisci una password.
              </Form.Control.Feedback>
            </Form.Group>
            <Button className="w-100 mt-3" type="submit">Login</Button>
          </Form>
          <Button
            className="w-100 mt-3"
            variant="secondary"
            onClick={handleRegister}
          >
            Registrati
          </Button>
        </div>
      </div>

      <Modal show={showSuccess} onHide={() => setShowSuccess(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Login Riuscito</Modal.Title>
        </Modal.Header>
        <Modal.Body>Accesso eseguito con successo! Verrai reindirizzato alla pagina principale.</Modal.Body>
      </Modal>
    </>
  );
}

export default LoginComponent;
