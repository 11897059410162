import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';

const DiplomiPage = () => {
    const navigate = useNavigate();
    const nominativo = sessionStorage.getItem('nominativo');

    const handleNavigate = (path) => {
            navigate(path);
    };

    return (
        <>
            <div
                className="d-flex align-items-center justify-content-center"
                style={{
                    minHeight: "100vh", // Questo si assicura che la pagina copra tutto l'altezza dello schermo
                    backgroundImage: 'url("/login.webp")', // Sostituisci '/login.webp' con il percorso del tuo sfondo
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '100vw',
                    paddingTop: '75px' // Per compensare l'altezza della navbar se presente
                }}
            >
                <Container style={{
                    maxWidth: "600px",
                    background: 'rgba(255, 255, 255, 0.85)', // Sfondo bianco semi-trasparente per leggibilità
                    padding: '20px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' // Ombra per una migliore visibilità
                }}>
                    <div style={{ marginBottom: '20px', color: 'black' }}>
                        <img src="/85a19cf2-a898-4560-b35c-17f9dd5cf238.png" alt="FM Montano Logo" style={{ width: '100px', height: 'auto', display: 'block', margin: 'auto' }} />
                        <h2>FM MONTANO</h2>
                        <p style={{ fontSize: '14px' }}>FMMONTANO, PRIMO GRUPPO IN ITALIA DEDICATO ALL’ATTIVITA’ FM IN OUTDOOR,
                            NASCE NEL 2015 DA UN’IDEA DI IZ1FUM IN COLLABORAZIONE CON IL MOUNTAIN QRP CLUB . NEL
                            GENNAIO 2020 FMMONTANO DIVENTA UNA REALTA’ AUTONOMA E ISTITUISCE UN SECONDO
                            PROGRAMMA DENOMINATO “RADIOMOBILE”. FMMONTANO PROMUOVE L’UTILIZZO DEI PALMARI
                            VHF/UHF PER SICUREZZA PERSONALE , CON L’OBIETTIVO DI RAGGIUNGERE MAGGIORI QRB MEDIANTE LA
                            COMPARAZIONE DI DIVERSE CONFIGURAZIONI DI ANTENNE, INDIVIDUAZIONE DEI QTH AD ALTO
                            POTENZIALE E COMPARAZIONE DI DIVERSI APPARATI. RADIOAMATORI ALPINISTI, ESCURSIONISTI, SCIATORI
                            E BIKERS, POSSONO MISURARSI CON LE LORO CAPACITA’ OPERATIVE NELLA PIU’ TOTALE PRATICITA’ E
                            LIBERTA’ NEI MOVIMENTI; PESI E INGOMBRI SONO RIDOTTI AL MINIMO CON LO ZAINO CHE RIACQUISTA
                            SPAZIO UTILE . DAL 2015 A OGGI, FM MONTANO HA TOTALIZZATO CIRCA 500 ATTIVAZIONI.</p>
                        <Button onClick={() => handleNavigate('/home')} variant="primary" style={{ width: '100%' }}>ENTRA IN FM MONTANO</Button>
                    </div>
                    <div style={{ marginBottom: '20px', color: 'black' }}>
                        <img src="/c1248ddd-5064-4e70-8c13-4d8b7af0a65e.png" alt="Radiomobile Logo" style={{ width: '100px', height: 'auto', display: 'block', margin: 'auto' }} />
                        <h2>RADIOMOBILE</h2>
                        <p style={{ fontSize: '14px' }}>RADIOMOBILE NASCE NEL GENNAIO 2020 AFFIANCANDO FMMONTANO NEL PROGRAMMA
                            ATTIVITA’. CON RADIOMOBILE PROPONIAMO LO SVILUPPO DELL’FM AL DI FUORI DELLE USCITE
                            PRETTAMENTE MONTANE, SIA IN AUTOVETTURA CHE A PIEDI IN QUELLI CHE PIU’ COMUNEMENTE
                            ABBIAMO DENOMINATO CHECK POINT. I CHECK POINT SONO PUNTI TATTICI INDIVIDUATI SUL TERRITORIO
                            AD ALTO POTENZIALE DI COLLEGAMENTO. POSSONO ESSERE SITI IN QUOTA COME IN PIANURA O A LIVELLO
                            MARE. LA CARATTERISTICA CHE LI ACCOMUNA E ‘ LA PARTICOLARE ATTITUDINE A RICEVERE SEGNALI DI
                            INTENSITA’ SUPERIORE A QUALSIASI QTH POSTO NELLE VICINANZE.</p>
                        <Button onClick={() => handleNavigate('/home_radio')} variant="primary" style={{ width: '100%' }}>ENTRA IN RADIOMOBILE</Button>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default DiplomiPage;